import React from "react";
import {
  SEO,
  MarkdownContent,
  Image,
  SocialMediaV2,
  MultipleComponentIterator,
} from "@bluefin/components";
import { Grid, Segment, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class MembershipPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.charismasalonak.com/membership/"}
        />
        <Grid
          className={"custom-page-membership custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"pqeifgzxiwidixlr"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={"dyeryjmebclvbtcn"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "db89d6f8-b091-47c5-92ec-8fe8421f8139",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"tstqppapkmdmtnmk"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"zytpuztbdssjjncc"}
              style={{ padding: 16 }}
              width={6}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "8002eeb7-3fb4-4777-b11b-a71c7b80efb6",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"qhimoiycfvlqwuuh"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "215786a6-b40e-4cd7-9e14-52a57267b66c",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"gchyuxfbuebzacyv"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={"entpilodgvpfyjvy"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__f8352d83-25f7-40ca-a29b-6ebdc2b438f8"}
                >
                  <div>
                    <Header
                      as={"h3"}
                      textAlign={"center"}
                      content={"Follow Us"}
                    />
                    <SocialMediaV2>
                      <MultipleComponentIterator
                        iterator={fishermanBusiness.socialMedia}
                        components={[
                          {
                            propMap: {
                              key: "_id",
                              url: "link",
                              platform: "type",
                            },
                            children: [
                              {
                                propMap: { icon: "type" },
                                component: <SocialMediaV2.Icon />,
                              },
                              {
                                propMap: { link: "link" },
                                component: <SocialMediaV2.Handle />,
                              },
                            ],
                            component: <SocialMediaV2.Platform />,
                          },
                        ]}
                      />
                    </SocialMediaV2>
                  </div>
                </React.Fragment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
    }
    fishermanBusinessWebsitePage(_id: { eq: 26908 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
